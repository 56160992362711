import axios from "axios";
//import { useNavigate } from "react-router-dom";
import {
  ADD_RDV,
  GET_CALL_HISTORY,
  GET_CONTACT,
  GET_PUBLIC_NOTES,
  GET_PRIVATE_NOTES,
  ADD_PRIVATE_NOTE,
  DELETE_PRIVATE_NOTE,
  ADD_CONTACT_STATUT,
  DELETE_CONTACT_STATUT,
  UPDATE_CONTACT_STATUT,
  ADD_PUBLIC_NOTE,
  DELETE_PUBLIC_NOTE,
  GET_CONTACT_NUMBERS,
  GET_CONTACT_ADDRESS,

  GET_APPEL_EMETTRE_BY_CONTACT,
  UPDATE_CONTACT,
  UPDATE_CONTACT2,
  ADD_CONTRAT,
  ADD_NUMBER,
  DELETE_NUMBER,
  ADD_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  GET_CONTACT_STATUT,
  GET_CONTACT_STATUT_MOBILE,
  GET_ACTIVITES_BY_CONTACT,
  GET_SUIVI_BY_CONTACT,
  GET_NOTES,
  GET_HISTORIQUE_AFFECTATION,
  ADD_CONTACT_STATUT_MOBILE,
  GET_CONTACT_PARRAINAGE,
  GET_RDV_BY_CONTACT,
  UPDATE_CALL_STATUS,
  UPDATE_FICHE_CONTACT,
  UPDATE_NUMBER,
  GET_CONTACTS_BY_OPTIONS
} from "./types";
import { successAlert, errorAlert } from "./alertActions";


export const addRDV = (value) => (dispatch) => {
  axios
    .post("rdv/", {
      note: value.note,
      dateAppel: value.dateAppel,
      important: value.important,
      contactId: value.contactId,

      typeRDV: value.typeRDV,
      approche: value.approche,

      statutAlarme: value.statutAlarme,
      statutMobile: value.statutMobile,
      rdvExploiteby:value.rdvExploiteby
      // dateFin: value.dateFin,
    })
    .then(function (response) {
      successAlert("le rendez-vous a été ajouté avec succès");
      dispatch({
        type: ADD_RDV,
        payload: response.data,
      });
    })
    .catch(function (error) {
      errorAlert("Problème lors de l'ajout du rendez-vous");
      console.log(error);
    });
};
export const addContact = (value) => (dispatch) => {
  console.log("addContact",value);
  axios
    .post("contact/",value)
    .then(function (response) {
      successAlert("Contact ajouté avec succès");
      dispatch(getContact(response.data.contact));
      dispatch(getNumbers(response.data.numeros));
      dispatch(getAddress(response.data.adresse));
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: response.data.statuts,
      });
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: response.data.statutsMobile,
      });
    })
    .catch(function (error) {
      console.log(error);
      errorAlert("Erreur lors de l'ajout du contact");
    });
};
export const addParrainage = (value) => (dispatch) => {
  console.log("addParrainage",value);
  axios
    .post("contact/parrainage", {
      parrainId: value.parrainId,
      approcheAlarme: value.approcheAlarme,
      approcheMobile: value.approcheMobile,
      email: value.email,
      typeContact: value.typeContact,
      secteur: value.secteur,
      categorieActivite: value.categorieActivite,
      ficheText: value.ficheText,
      nomCompagnie: value.nomCompagnie,
      firstname: value.firstname,
      classification: value.classification,
      language: value.language,
      lastname: value.lastname,
      operateur: value.operateur,
      sexe: value.sexe,
      add: value.add,
      numero: value.numero,
    })
    .then(function (response) {
      successAlert("Contact parrainé ajouté avec succès");

      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error);
      errorAlert("Erreur lors de l'ajout du contact parrainé");
    });
};

export const searchNumber = (numero, history) => (dispatch) => {
  // dispatch({
  //   type: LOADING_STARTED_CONTACT_STATE,
  // });

  axios
    .get("appelEmettre/byNumber/" + numero)
    .then((res) => {
      dispatch({
        type: GET_APPEL_EMETTRE_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert(
        "Erreur lors de la récupération des actions à émettre du contact 11111"
      );
      dispatch({
        type: GET_APPEL_EMETTRE_BY_CONTACT,
        payload: [],
      });
    });
  axios
    .get("rdv/byNumber/" + numero)
    .then((res) => {
      dispatch({
        type: GET_RDV_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      
      errorAlert("Erreur lors de la récupération des RDV du contact");
      dispatch({
        type: GET_RDV_BY_CONTACT,
        payload: [],
      });
    });

  axios
    .get("contact/byNumber/" + numero)
    .then((res) => {
      if (res.data.contact) {
        dispatch(getContact(res.data.contact));
        dispatch(getNumbers(res.data.numeros));
        dispatch(getAddress(res.data.adresse));
        dispatch({
          type: GET_CONTACT_PARRAINAGE,
          payload: res.data.listParrainage,
        });
      } else {
        dispatch(getContact({}));
        dispatch(getNumbers([{ number: numero }]));
        dispatch(getAddress([]));
        dispatch({
          type: GET_CONTACT_PARRAINAGE,
          payload: [],
        });
      }
    })
    .catch((err) => {
      errorAlert("Pas de contact pour le numéro recherché");
      dispatch({
        type: GET_CONTACT,
        payload: {},
      });
      dispatch(getNumbers([{ number: numero }]));
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: [],
      });
    });

  axios
    .get("call/history/" + numero)
    .then((res) => {
      dispatch(getCallHistory(res.data));
    })
    .catch((err) => {
      dispatch({
        type: GET_CALL_HISTORY,
        payload: [],
      });
    });
  axios
    .get("contactStatut/ByContactNumber/" + numero)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: [],
      });
    });
  axios
    .get("contactStatutMobile/ByContactNumber/" + numero)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: [],
      });
    });
  axios
    .post("contactNotes/noteByNumber", {
      numero: numero,
    })
    .then((res) => {
      dispatch(getPublicNotes(res.data.publicNotes));
      dispatch(getPrivateNotes(res.data.privateNotes));
    })
    .catch((err) => {
      dispatch({
        type: GET_PUBLIC_NOTES,
        payload: [],
      });
      dispatch({
        type: GET_PRIVATE_NOTES,
        payload: [],
      });
    });
  axios
    .get("noteDynamique/")
    .then((res) => {
      dispatch({
        type: GET_NOTES,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_NOTES,
        payload: [],
      });
    });
  axios
    .post("/api/activites/", {
      contactNumber: numero,
    })
    .then((res) => {
      dispatch({
        type: GET_ACTIVITES_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ACTIVITES_BY_CONTACT,
        payload: [],
      });
    });
  axios
    .get("api/prospectionCommerciale/ByNumber/" + numero)
    .then((res) => {
      dispatch({
        type: GET_SUIVI_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SUIVI_BY_CONTACT,
        payload: [],
      });
    });

    history.push("/contact");
};
export const getContact = (numero) => {
  return {
    type: GET_CONTACT,
    payload: numero,
  };
};
export const searchContact = (numero, history) => (dispatch) => {
  axios
    .get("appelEmettre/byContact/" + numero)
    .then((res) => {
      dispatch({
        type: GET_APPEL_EMETTRE_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert(
        "Erreur lors de la récupération des actions à émettre du contact 2222"
      );
      dispatch({
        type: GET_APPEL_EMETTRE_BY_CONTACT,
        payload: [],
      });
    });
  axios
    .get("rdv/byContact/" + numero)
    .then((res) => {
      dispatch({
        type: GET_RDV_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.error("errerrerrerrerrerrerr",err)
     
      dispatch({
        type: GET_RDV_BY_CONTACT,
        payload: [],
      });
    });
  axios
    .get("contact/byId/" + numero)
    .then((res) => {
      dispatch(getContact(res.data.contact));
      dispatch(getNumbers(res.data.numeros));
      dispatch(getAddress(res.data.adresse));
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: res.data.listParrainage,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT,
        payload: {},
      });
      dispatch({
        type: GET_CONTACT_NUMBERS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: [],
      });
    });
  axios
    .get("call/historyById/" + numero)
    .then((res) => {
      dispatch(getCallHistory(res.data));
    })
    .catch((err) =>
      dispatch({
        type: GET_CALL_HISTORY,
        payload: [],
      })
    );
  axios
    .get("contactStatut/ByContactId/" + numero)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: [],
      });
    });
  axios
    .get("contactStatutMobile/ByContactId/" + numero)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: [],
      });
    });

  axios
    .post("contactNotes/noteById", {
      numero: numero,
    })
    .then((res) => {
      dispatch(getPublicNotes(res.data.publicNotes));
      dispatch(getPrivateNotes(res.data.privateNotes));
    })
    .catch((err) => {
      dispatch({
        type: GET_PUBLIC_NOTES,
        payload: [],
      });
      dispatch({
        type: GET_PRIVATE_NOTES,
        payload: [],
      });
    });
  axios
    .get("noteDynamique/")
    .then((res) => {
      dispatch({
        type: GET_NOTES,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_NOTES,
        payload: [],
      });
    });
  axios
    .post("api/activites/", {
      contactId: numero,
    })
    .then((res) => {
      dispatch({
        type: GET_ACTIVITES_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ACTIVITES_BY_CONTACT,
        payload: [],
      });
    });
  axios
    .get("api/prospectionCommerciale/byId/" + numero)
    .then((res) => {
      dispatch({
        type: GET_SUIVI_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SUIVI_BY_CONTACT,
        payload: [],
      });
    });

    history.push("/contact");
};

export const updateContact = (value) => (dispatch) => {
  axios
    .put("contact/",value)
    .then((res) => {
      if(value.sourceUpdate==="EnTantQueAbonneAlarme"){
        successAlert("Approche Alarme activée avec succès.");
      }else if(value.sourceUpdate==="EnTantQueAbonneMobile"){
        successAlert("Approche Mobile activée avec succès.");
      }
      else{
        successAlert("Contact modifié avec succès");
      }
     
      dispatch({
        type: UPDATE_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("Erreur lors de la modification du contact");
      dispatch({
        type: UPDATE_CONTACT,
        payload: {},
      });
    });
};


export const updateLanguageContact = (value) => (dispatch) => {
  axios
    .put("contact/updateLanguageContact",value)
    .then((res) => {
      dispatch({
        type: UPDATE_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_CONTACT,
        payload: {},
      });
    });
};

export const deleteContact = (numero, history) => (dispatch) => {
  axios
    .delete("contact/", { data: { contactId: numero } })
    .then(function (response) {
      successAlert("Contact supprimé avec success");
    })
    .catch(function (error) {
      errorAlert("Erreur lors de la suppression du contact");
      console.log(error);
    });
    history.push("/actionEmettre");
};
//BAYA
export const deleteManyContacts = (value) =>()=>{
  axios
  .put("contact/deleteManyContacts",value)
  .then(function(responce){
    successAlert("Liste des contacts supprimée avec succès");
    window.location.reload();
  })
  .catch(function(error){
    console.log({error:error});
    errorAlert("Erreur lors de la suppression des contacts !")
  })
}

export const deletePrivateNote = (numero) => (dispatch) => {
  return dispatch({
    type: DELETE_PRIVATE_NOTE,
    payload: numero,
  });
};

export const addPrivateNote = (numero) => (dispatch) => {
  return dispatch({
    type: ADD_PRIVATE_NOTE,
    payload: numero,
  });
};

export const deletePublicNote = (numero) => (dispatch) => {
  return dispatch({
    type: DELETE_PUBLIC_NOTE,
    payload: numero,
  });
};

export const addPublicNote = (numero) => (dispatch) => {
  return dispatch({
    type: ADD_PUBLIC_NOTE,
    payload: numero,
  });
};

export const getPublicNotes = (numero) => {
  return {
    type: GET_PUBLIC_NOTES,
    payload: numero,
  };
};

export const getPrivateNotes = (numero) => {
  return {
    type: GET_PRIVATE_NOTES,
    payload: numero,
  };
};

export const getNumbers = (numero) => {
  return {
    type: GET_CONTACT_NUMBERS,
    payload: numero,
  };
};

export const getAddress = (numero) => {
  return {
    type: GET_CONTACT_ADDRESS,
    payload: numero,
  };
};

export const getStatut = (numero) => {
  return {
    type: GET_CONTACT_STATUT,
    payload: numero,
  };
};

export const getCallHistory = (numero) => {
  return {
    type: GET_CALL_HISTORY,
    payload: numero,
  };
};

export const addStatut = (value) => (dispatch) => {
  axios
    .post("contact/addEtat", {
      id: value.id,
      vente: value.vente,
      statut: value.statut,
      raison: value.raison,
      budget: value.budget,
      koBudget: value.koBudget,
      koType: value.koType,
      besoin: value.besoin,
      echeance: { annee: value.annee, mois: value.mois },
      echeanceDetails: value.echeanceDetails,
      horsCibleDetails: value.horsCibleDetails,
      injoignableDetails: value.injoignableDetails,
      decision: value.decision,
      note: value.note,
      userId: value.userId,
    })
    .then(function (response) {
      successAlert("statut modifié avec succès");
      dispatch({
        type: ADD_CONTACT_STATUT,
        payload: value,
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors de la modification du statut");
    });
};

export const deleteStatut = (numero) => (dispatch) => {
  axios
    .delete("contact/deleteEtat", { data: { id: numero } })
    .then(function (response) {
      dispatch({
        type: DELETE_CONTACT_STATUT,
        payload: numero,
      });
      successAlert("status deleted succesfully");
    })
    .catch(function (error) {
      errorAlert("OOPS");
      console.log(error);
    });
};



export const updateNumber = (value) => (dispatch) => {
  axios
    .post("phoneNumber/updateNumber", {
      numberId: value.numberId,
      description: value.description,
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: UPDATE_NUMBER,
        payload: res.data,
      });
      // successAlert("Numéro de téléphone ajouté avec succès");
    })
    .catch((err) => {
      errorAlert(err.response.data.message);
    });
};
export const addNumber = (value) => (dispatch) => {
  axios
    .post("phoneNumber/", {
      contactId: value.contactId,
      number: value.numero,
      description: value.description,
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: ADD_NUMBER,
        payload: res.data,
      });
      successAlert("Numéro de téléphone ajouté avec succès");
    })
    .catch((err) => {
      errorAlert(err.response.data.message);
    });
};

export const deleteNumber = (numero) => (dispatch) => {
  axios
    .delete("phoneNumber/", { data: { number: numero } })
    .then(function (response) {
      successAlert("Numéro supprimé avec success");
      dispatch({
        type: DELETE_NUMBER,
        payload: numero,
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors de la suppression du numéro");
      console.log(error);
    });
};

export const addAddress = (value) => (dispatch) => {
  axios
    .post("adresse/", {
      contactId: value.contactId,
      rue: value.rue,
      ville: value.ville,
      region: value.region,
      province: value.province,
      codePostal: value.codePostal,
      description: value.description,
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: ADD_ADDRESS,
        payload: res.data.adresse,
      });
      dispatch({
        type: UPDATE_CONTACT_STATUT,
        payload: { adresse: res.data.adresse, statuts: res.data.statuts },
      });
      successAlert("Adresse(s) ajoutée(s) avec succès");
    })
    .catch((err) => {
      console.log(err);
      errorAlert("Erreur lors de l'ajout d'adresse");
    });
};

export const updateAddress = (value) => (dispatch) => {
  axios
    .put("adresse/", {
      id: value.id,
      province: value.province,
      region: value.region,
      ville: value.ville,
      rue: value.rue,
      codePostal: value.codePostal,
      description: value.description,
    })
    .then((res) => {
      dispatch({
        type: UPDATE_ADDRESS,
        payload: res.data,
      });
      successAlert("Adresse(s) modifiée(s) avec succès");
    })
    .catch((err) => {
      errorAlert("Erreur lors de la modification d'adresse");
    });
};

export const deleteAddress = (numero) => (dispatch) => {
  axios
    .delete("adresse/", { data: { id: numero } })
    .then(function (response) {
      successAlert("Adresse(s) suprimée(s) avec succès");
      dispatch({
        type: DELETE_ADDRESS,
        payload: numero,
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors de la suppression d'adresse");
      console.log(error);
    });
};



export const searchContactFiche = (numero) => (dispatch) => {
  // axios
  //   .get("appelEmettre/byContact/" + numero)
  //   .then((res) => {
  //     dispatch({
  //       type: GET_APPEL_EMETTRE_BY_CONTACT,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((err) => {
  //     errorAlert("OOPS");
  //     dispatch({
  //       type: GET_APPEL_EMETTRE_BY_CONTACT,
  //       payload: [],
  //     });
  //   });
  axios
    .get("contact/byId/" + numero)
    .then((res) => {
      dispatch(getContact(res.data.contact));
      dispatch(getNumbers(res.data.numeros));
      dispatch(getAddress(res.data.adresse));
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: res.data.listParrainage,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT,
        payload: {},
      });
      dispatch({
        type: GET_CONTACT_NUMBERS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: [],
      });
    });
  axios
    .get("call/historyById/" + numero)
    .then((res) => {
      dispatch(getCallHistory(res.data));
    })
    .catch((err) =>
      dispatch({
        type: GET_CALL_HISTORY,
        payload: [],
      })
    );
  axios
    .get("contactStatut/ByContactId/" + numero)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: [],
      });
    });
  axios
    .get("contactStatutMobile/ByContactId/" + numero)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: [],
      });
    });
  axios
    .post("contactNotes/noteById", {
      numero: numero,
    })
    .then((res) => {
      dispatch(getPublicNotes(res.data.publicNotes));
      dispatch(getPrivateNotes(res.data.privateNotes));
    })
    .catch((err) => {
      dispatch({
        type: GET_PUBLIC_NOTES,
        payload: [],
      });
      dispatch({
        type: GET_PRIVATE_NOTES,
        payload: [],
      });
    });

  // axios
  //   .post("api/activites/", {
  //     contactId: numero,
  //   })
  //   .then((res) => {
  //     dispatch({
  //       type: GET_ACTIVITES_BY_CONTACT,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: GET_ACTIVITES_BY_CONTACT,
  //       payload: [],
  //     });
  //   });
  // axios
  //   .get("api/prospectionCommerciale/byId/" + numero)
  //   .then((res) => {
  //     dispatch({
  //       type: GET_SUIVI_BY_CONTACT,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: GET_SUIVI_BY_CONTACT,
  //       payload: [],
  //     });
  //   });

  //  history.push("/contact");
};
export const affectContact = (value) => (dispatch) => {
  axios
    .post("contact/affectContact", value)
    .then(function (response) {
      successAlert("Affectation réussie");
    })
    .catch(function (error) {
      errorAlert("OOPS");
    });
};

export const acceptAffectContact = (value) => (dispatch) => {
  axios
    .post("contact/acceptAffectContact", value)
    .then(function (res) {
      dispatch(getContact(res.data));
      successAlert("Affectation réussie");
    })
    .catch(function (error) {
      errorAlert("OOPS");
    });
};
export const affectContactById = (value) => (dispatch) => {
  axios
    .post("contact/affectContact/byId", value)
    .then((res) => {
      dispatch(getContact(res.data.contact));
      dispatch(getNumbers(res.data.numeros));
      dispatch(getAddress(res.data.adresse));
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: res.data.listParrainage,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT,
        payload: {},
      });
      dispatch({
        type: GET_CONTACT_NUMBERS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: [],
      });
      errorAlert("OOPS");
    });
   // history.push("/contact");
};

export const getHistoriqueAffectation = (value) => (dispatch) => {
  axios
    .post("contact/getHistoriqueAffectation", value)
    .then((res) => {
      // successAlert("Affectation réussie");
      dispatch({
        type: GET_HISTORIQUE_AFFECTATION,
        payload: res.data,
      });
    })
    .catch(function (error) {
      errorAlert("Erreur lors de la récuperation de l'historique Affectation");
      dispatch({
        type: GET_HISTORIQUE_AFFECTATION,
        payload: [],
      });
    });
};
export const searchContact2 = (numero, history) => (dispatch) => {
  axios
    .get("appelEmettre/byContact/" + numero)
    .then((res) => {
      dispatch({
        type: GET_APPEL_EMETTRE_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert(
        "Erreur lors de la récupération des actions à émettre du contact  33333"
      );
      dispatch({
        type: GET_APPEL_EMETTRE_BY_CONTACT,
        payload: [],
      });
    });
  axios
    .get("rdv/byContact/" + numero)
    .then((res) => {
      dispatch({
        type: GET_RDV_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("Erreur lors de la récupération des RDV du contact");
      dispatch({
        type: GET_RDV_BY_CONTACT,
        payload: [],
      });
    });
  axios
    .get("contact/byId/" + numero)
    .then((res) => {
      dispatch(getContact(res.data.contact));
      dispatch(getNumbers(res.data.numeros));
      dispatch(getAddress(res.data.adresse));
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: res.data.listParrainage,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT,
        payload: {},
      });
      dispatch({
        type: GET_CONTACT_NUMBERS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: [],
      });
    });
  axios
    .get("call/historyById/" + numero)
    .then((res) => {
      dispatch(getCallHistory(res.data));
    })
    .catch((err) =>
      dispatch({
        type: GET_CALL_HISTORY,
        payload: [],
      })
    );
  axios
    .get("contactStatut/ByContactId/" + numero)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: [],
      });
    });
  axios
    .get("contactStatutMobile/ByContactId/" + numero)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: [],
      });
    });

  axios
    .post("contactNotes/noteById", {
      numero: numero,
    })
    .then((res) => {
      dispatch(getPublicNotes(res.data.publicNotes));
      dispatch(getPrivateNotes(res.data.privateNotes));
    })
    .catch((err) => {
      dispatch({
        type: GET_PUBLIC_NOTES,
        payload: [],
      });
      dispatch({
        type: GET_PRIVATE_NOTES,
        payload: [],
      });
    });
  axios
    .get("noteDynamique/")
    .then((res) => {
      dispatch({
        type: GET_NOTES,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_NOTES,
        payload: [],
      });
    });
  axios
    .post("api/activites/", {
      contactId: numero,
    })
    .then((res) => {
      dispatch({
        type: GET_ACTIVITES_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ACTIVITES_BY_CONTACT,
        payload: [],
      });
    });
  axios
    .get("api/prospectionCommerciale/byId/" + numero)
    .then((res) => {
      dispatch({
        type: GET_SUIVI_BY_CONTACT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SUIVI_BY_CONTACT,
        payload: [],
      });
    });
};
export const resetContact = (value) => (dispatch) => {
  axios
    .get("contact/resetContact")
    .then((res) => {
      dispatch({
        type: GET_APPEL_EMETTRE_BY_CONTACT,
        payload: [],
      });
      dispatch({
        type: GET_RDV_BY_CONTACT,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT,
        payload: {},
      });
      dispatch({
        type: GET_CONTACT_NUMBERS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_ADDRESS,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_PARRAINAGE,
        payload: [],
      });
      dispatch({
        type: GET_CALL_HISTORY,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_STATUT,
        payload: [],
      });
      dispatch({
        type: GET_CONTACT_STATUT_MOBILE,
        payload: [],
      });
      dispatch({
        type: GET_PUBLIC_NOTES,
        payload: [],
      });
      dispatch({
        type: GET_PRIVATE_NOTES,
        payload: [],
      });
      dispatch({
        type: GET_NOTES,
        payload: [],
      });
      dispatch({
        type: GET_ACTIVITES_BY_CONTACT,
        payload: [],
      });
      dispatch({
        type: GET_SUIVI_BY_CONTACT,
        payload: [],
      });
    })
    .catch((err) => {
      errorAlert("Erreur lors de la remise à zero du contact");
    });
};

export const getContactNotesById = (numero) => (dispatch) => {
  axios
    .post("contactNotes/noteById", {
      numero: numero,
    })
    .then((res) => {
      dispatch(getPublicNotes(res.data.publicNotes));
      dispatch(getPrivateNotes(res.data.privateNotes));
    })
    .catch((err) => {
      dispatch({
        type: GET_PUBLIC_NOTES,
        payload: [],
      });
      dispatch({
        type: GET_PRIVATE_NOTES,
        payload: [],
      });
    });
};

export const updateCallStatus = (value) => (dispatch) => {
  axios
    .post("call/updateCallStatus", value)
    .then((res) => {
      dispatch({
        type: UPDATE_CALL_STATUS,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("Erreur lors de la mise à jour du statut appel");
      dispatch({
        type: UPDATE_CALL_STATUS,
        payload: {},
      });
    });
};

export const addDemandeAffectation = (value) => (dispatch) => {
  axios
    .post("demandeAffectation/addDemandeAffectation", value)
    .then((res) => {
       dispatch({
        type:UPDATE_CONTACT2,
        payload:res.data[3]
       })
      successAlert("Demande envoyée avec succès");
    })
    .catch((err) => {
      console.error("err",err)
      errorAlert("Demande non aboutie, essayez de nouveau !");
    });
};

export const autoAffectationbyOp = (value,history) =>(dispatch)=>{
  console.log("autoAffectationbyOp action",value,history);
  axios
  .post("demandeAffectation/autoAffectationbyOp", value)
  .then((res)=>{
    console.log("res",res);
   successAlert("Demande d'affectation acceptée")
    history.push(`/contact?name=${value.contactId}`)
   window.location.reload(); 
  })
  .catch((err)=>{
    console.error(err);
    errorAlert("Demande non aboutie, essayez de nouveau !")
  })
}
export const addContrat = (value) => (dispatch) => {
  axios
    .post(
      value.approche === "Alarme" || value.approche==="Internet"
        ? "contactStatut/addContrat/"
        : value.approche === "Mobile"
        ? "contactStatutMobile/addContrat/"
        : "",
      {
        contactId: value.contactId,
        adresseId: value.adresseId,
        approche: value.approche,
        contrat: {
          approche: value.contrat.approche,
          montant: value.contrat.montant,
          linesNumber:value.contrat.linesNumber,
          satisfait: value.contrat.satisfait,
          note: value.contrat.note,
          typePaiement: value.contrat.typePaiment,
          moisFin: value.contrat.moisFin,
          anneeFin: value.contrat.anneeFin,
          compagnie: value.contrat.compagnie,
          createdAt: new Date(),
          userId: value.contrat.userId,
        },
     
      }
    )
    .then((res) => {
      if (value.approche === "Alarme") {
        console.log("res", res.data);
        dispatch({
          type: ADD_CONTRAT,
          payload: res.data.adresse,
        });
        dispatch({
          type: UPDATE_CONTACT_STATUT,
          payload: { adresse: res.data.adresse, statuts: res.data.statuts },
        });
        successAlert("Contrat ajouté avec succès");
      }
      if (value.approche === "Mobile") {
        console.log("reeee", res.data);
        dispatch({
          type: ADD_CONTACT_STATUT_MOBILE,
          payload: res.data,
        });
        successAlert("Contrat ajouté avec succès");
      }
    })
    .catch((err) => {
      console.log(err);
      errorAlert("Erreur lors de l'ajout du contrat");
    });
};
export const UpdateFicheContact = (value) => (dispatch) => {
  console.log("value UpdateFicheContact",value)
  axios
  .put("contact/updateFicheCompanie",value)
    .then((res) => {
      successAlert("Fiche compagnie modifiée avec succès");
      dispatch({
        type: UPDATE_FICHE_CONTACT,
        payload: res.data,
      });
    })
    .catch (error=>{
      console.log(error);
      errorAlert("Erreur lors de la modification de la fiche compagnie");
    })
};


export const connectContacts = (value) =>(dispatch) =>{
  axios
  .put("contact/connectContacts",value)
  .then((res)=>{
    successAlert("Contact associé avec succée");
    dispatch({
      type:UPDATE_CONTACT,
      payload:res.data
    })
  })
  .catch((error)=>{
    errorAlert("Echec d'association de contact");
    dispatch({
      type:UPDATE_CONTACT,
      payload:{}
    })
  })
}

export const getContactsByOptions=(page,value)=>async(dispatch)=>{
  console.log("value", value);
  try {
   const responce = await axios.get(`/contact/getContactsByOptions?page=${page}&limit=${15}`,value);
    dispatch({
      type:GET_CONTACTS_BY_OPTIONS,
      payload:responce
    })
  } catch (error) {
   console.error(error) ;
   dispatch({
    type:GET_CONTACTS_BY_OPTIONS,
    payload:{}
  })
  }
}