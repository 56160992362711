import {
  ADD_SECTOR_AVAILABILITY,
  GET_ALL_SECTOR_AVAILABILITY,
  UPDATE_SECTOR_AVAILABILITY,
  COPIE_SECTOR_AVAILABILITY,
  COPIE_SECTOR_AVAILABILITY_BYID,
  DELETE_SECTOR_AVAILABILITY_BYID,
} from "../../actions/types";

const initialState = {
  allSectorAvalability: [],
};

const sectorAvailabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SECTOR_AVAILABILITY:
      return {
        ...state,
        allSectorAvalability: action.payload,
      };
    case UPDATE_SECTOR_AVAILABILITY:
      return {
        ...state,
        allSectorAvalability: state.allSectorAvalability.map((el) => {
          return el._id === action.payload._id
            ? {
                ...el,
                title: action.payload.title,
                start: action.payload.start,
                end: action.payload.end,
              }
            : el;
        }),
      };
    case ADD_SECTOR_AVAILABILITY:
      return {
        ...state,
        allSectorAvalability: [...state.allSectorAvalability, action.payload],
      };

    case COPIE_SECTOR_AVAILABILITY_BYID:
      return {
        ...state,
        allSectorAvalability: [...state.allSectorAvalability, action.payload],
      };

    case COPIE_SECTOR_AVAILABILITY:
      return {
        ...state,
        allSectorAvalability: [
          ...state.allSectorAvalability,
          ...action.payload,
        ],
      };
    case DELETE_SECTOR_AVAILABILITY_BYID:
      return {
        ...state,
        allSectorAvalability:state.allSectorAvalability.filter(el=>el._id !== action.payload.id),
      };
    default:
      return state;
  }
};
export default sectorAvailabilityReducer;
