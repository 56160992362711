//errors
export const GET_ERRORS = "GET_ERRORS";

//user
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const ADD_USER = "ADD_USER";
export const GET_USERS = "GET_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_JOURNAL = "GET_JOURNAL";
export const GET_JOURNAL_STAT = "GET_JOURNAL_STAT";
export const GET_ACTIVITIES_USER = "GET_ACTIVITIES_USER";

//contact
export const GET_NUMBER_SEARCHECD = "GET_NUMBER_SEARCHECD";
export const GET_CALL_HISTORY = "GET_CALL_HISTORY";
export const GET_CONTACT = "GET_CONTACT";
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_NOT_AFFECTED = "GET_CONTACTS_NOT_AFFECTED";
export const GET_CONTACTS_NOT_AFFECTED_ALARME = "GET_CONTACTS_NOT_AFFECTED_ALARME";
export const GET_CONTACTS_NOT_AFFECTED_MOBILE = "GET_CONTACTS_NOT_AFFECTED_MOBILE";
export const GET_CONTACTS_HORSCIRCUIT_ALARME = "GET_CONTACTS_HORSCIRCUIT_ALARME";
export const UPDATE_CONTACTS_HORSCIRCUIT_ALARME = "UPDATE_CONTACTS_HORSCIRCUIT_ALARME";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACT2 = "UPDATE_CONTACT2";
export const GET_CONTACT_ADDRESS = "GET_CONTACT_ADDRESS";
export const GET_CONTACT_NUMBERS = "GET_CONTACT_NUMBERS";
export const GET_ALL_CONTACT_BY_USER = "GET_ALL_CONTACT_BY_USER";
export const ADD_CONTRAT = "ADD_CONTRAT";
export const ADD_NUMBER = "ADD_NUMBER";
export const DELETE_NUMBER = "DELETE_NUMBER";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UPDATE_CONTACT_STATUT2 = "UPDATE_CONTACT_STATUT2";
export const UPDATE_FICHE_CONTACT = "UPDATE_FICHE_CONTACT"
export const GET_CONTACTS_SOUS_CONTRAT_BY_ID = "GET_CONTACTS_SOUS_CONTRAT_BY_ID";
export const GET_AFFECTED_CONTACTS = "GET_AFFECTED_CONTACTS";
export const GET_AFFECTED_CONTACTS_ALARME = "GET_AFFECTED_CONTACTS_ALARME";
export const GET_AFFECTED_CONTACTS_MOBILE = "GET_AFFECTED_CONTACTS_MOBILE";  
export const GET_HISTORIQUE_AFFECTATION = "GET_HISTORIQUE_AFFECTATION";
export const GET_FUTURE_LEAD_ECHEANCE = "GET_FUTURE_LEAD_ECHEANCE";
export const UPLOAD_CONTACTS = "UPLOAD_CONTACTS";
export const GET_CONTACT_PARRAINAGE = "GET_CONTACT_PARRAINAGE";
export const LOADING_STARTED_CONTACT_STATE = "LOADING_STARTED_CONTACT_STATE";
export const LOADING_ENDED_CONTACT_STATE = "LOADING_ENDED_CONTACT_STATE";
export const RESET_CONTACT = "RESET_CONTACT";
export const ACCEPT_AFFECTED_CONTACT = "ACCEPT_AFFECTED_CONTACT";
export const UPDATE_NUMBER = "UPDATE_NUMBER";
export const GET_SOUS_CONTRAT = "GET_SOUS_CONTRAT";
export const GET_FUTURE_ECHEANCE = "GET_FUTURE_ECHEANCE";
export const GET_FUTURE_BESOIN = "GET_FUTURE_BESOIN";
export const GET_HORS_CIBLE = "GET_HORS_CIBLE";
export const GET_SOUS_CONTRAT_ALARME = "GET_SOUS_CONTRAT_ALARME";
export const GET_FUTURE_ECHEANCE_ALARME = "GET_FUTURE_ECHEANCE_ALARME";
export const GET_FUTURE_BESOIN_ALARME = "GET_FUTURE_BESOIN_ALARME";
export const GET_KO_BUDGET_ALARME = "GET_KO_BUDGET_ALARME";
export const GET_KO_REFUS_ALARME = "GET_KO_REFUS_ALARME";
export const GET_KO_AUTORITE_ALARME = "GET_KO_AUTORITE_ALARME";
export const GET_PAS_INTERESSE_ALARME = "GET_PAS_INTERESSE_ALARME";
export const GET_KO_AUTRE_ALARME = "GET_KO_AUTRE_ALARME";
export const GET_SOUS_CONTRAT_MOBILE = "GET_SOUS_CONTRAT_MOBILE";
export const GET_FUTURE_ECHEANCE_MOBILE = "GET_FUTURE_ECHEANCE_MOBILE";
export const GET_FUTURE_BESOIN_MOBILE = "GET_FUTURE_BESOIN_MOBILE";
export const GET_KO_BUDGET_MOBILE = "GET_KO_BUDGET_MOBILE";
export const GET_KO_REFUS_MOBILE = "GET_KO_REFUS_MOBILE";
export const GET_KO_AUTORITE_MOBILE = "GET_KO_AUTORITE_MOBILE";
export const GET_PAS_INTERESSE_MOBILE = "GET_PAS_INTERESSE_MOBILE";
export const GET_KO_AUTRE_MOBILE = "GET_KO_AUTRE_MOBILE";
export const GET_CONTACTS_BY_OPTIONS = "GET_CONTACTS_BY_OPTIONS";

//activites
export const GET_ACTIVITES = "GET_ACTIVITES";
export const GET_ACTIVITES_BY_CONTACT = "GET_ACTIVITES_BY_CONTACT";
//notes
export const GET_PUBLIC_NOTES = "GET_PUBLIC_NOTES";
export const GET_PRIVATE_NOTES = "GET_PRIVATE_NOTES";
export const ADD_PRIVATE_NOTE = "ADD_PRIVATE_NOTE";
export const DELETE_PRIVATE_NOTE = "DELETE_PRIVATE_NOTE";
export const ADD_PUBLIC_NOTE = "ADD_PUBLIC_NOTE";
export const DELETE_PUBLIC_NOTE = "DELETE_PUBLIC_NOTE";
export const GET_NOTES_ALL_CONTACTS = "GET_NOTES_ALL_CONTACTS";
//statut
export const GET_CONTACT_STATUT = "GET_CONTACT_STATUT";
export const GET_CONTACT_STATUT_BY_USER = "GET_CONTACT_STATUT_BY_USER";
export const ADD_CONTACT_STATUT = "ADD_CONTACT_STATUT";
export const DELETE_CONTACT_STATUT = "DELETE_CONTACT_STATUT";
export const UPDATE_CONTACT_STATUT = "UPDATE_CONTACT_STATUT";
//statut Mobile
export const GET_CONTACT_STATUT_MOBILE = "GET_CONTACT_STATUT_MOBILE";
export const GET_CONTACT_STATUT_MOBILE_BY_USER = "GET_CONTACT_STATUT_MOBILE_BY_USER";
export const ADD_CONTACT_STATUT_MOBILE = "ADD_CONTACT_STATUT_MOBILE";
export const DELETE_CONTACT_STATUT_MOBILE = "DELETE_CONTACT_STATUT_MOBILE";
export const UPDATE_CONTACT_STATUT_MOBILE = "UPDATE_CONTACT_STATUT_MOBILE";
// RDV
export const ADD_RDV = "ADD_RDV";
export const UPDATE_RDV_BY_CONTACT = "UPDATE_RDV_BY_CONTACT";
export const UPDATE_RDV_BY_USER = "UPDATE_RDV_BY_USER";
export const UPDATE_TIME_RDV = "UPDATE_TIME_RDV";
export const GET_RDV = "GET_RDV";
export const GET_RDV_BY_CONTACT= "GET_RDV_BY_CONTACT";
export const GET_RDV_AFFECTED_TO_USER= "GET_RDV_AFFECTED_TO_USER";
export const GET_RDV_CREATED_BY_USER= "GET_RDV_CREATED_BY_USER";
//appelsEmettre
export const ADD_APPEL_EMETTRE = "ADD_APPEL_EMETTRE";
export const DELETE_APPEL_EMETTRE = "DELETE_APPEL_EMETTRE";
export const GET_APPEL_EMETTRE = "GET_APPEL_EMETTRE";
export const GET_APPEL_EMETTRE_BY_CONTACT = "GET_APPEL_EMETTRE_BY_CONTACT";
export const UPDATE_APPEL_EMETTRE = "UPDATE_APPEL_EMETTRE";
export const UPDATE_TIME_APPEL_EMETTRE = "UPDATE_TIME_APPEL_EMETTRE";
export const UPDATE_DATE_APPEL_EMETTRE = "UPDATE_DATE_APPEL_EMETTRE";
export const UPDATE_APPEL_EMETTRE_NOTIFIED = "UPDATE_APPEL_EMETTRE_NOTIFIED";
export const DELETE_APPEL_EMETTRE_BY_CONTACT ="DELETE_APPEL_EMETTRE_BY_CONTACT";
export const UPDATE_APPEL_EMETTRE_BY_CONTACT ="UPDATE_APPEL_EMETTRE_BY_CONTACT";
//compagnie
export const GET_COMPAGNIES = "GET_COMPAGNIES";
export const ADD_COMPAGNIE = "ADD_COMPAGNIE";
export const UPDATE_COMPAGNIE = "UPDATE_COMPAGNIE";
//products
//equipements
export const GET_EQUIPEMENTS = "GET_EQUIPEMENTS";
export const ADD_EQUIPEMENT = "ADD_EQUIPEMENT";
export const UPDATE_EQUIPEMENT = "UPDATE_EQUIPEMENT";
export const DELETE_EQUIPEMENT = "DELETE_EQUIPEMENT";
//forfaits
export const GET_FORFAITS = "GET_FORFAITS";
export const ADD_FORFAIT = "ADD_FORFAIT";
export const UPDATE_FORFAIT = "UPDATE_FORFAIT";
export const DELETE_FORFAIT = "DELETE_FORFAIT";
//ventes
export const UPDATE_SUIVI_STATUS = "UPDATE_SUIVI_STATUS";
export const GET_SUIVI_BY_USER_CONTACT = "GET_SUIVI_BY_USER_CONTACT";
export const GET_SUIVI_BY_CONTACT = "GET_SUIVI_BY_CONTACT";
export const GET_VENTES = "GET_VENTES";
export const GET_VENTE_BY_ID = "GET_VENTE_BY_ID";
//suivis stand by
export const GET_SUIVI_STAND_BY = "GET_SUIVI_STAND_BY";
export const ADD_SUIVI_STAND_BY = "ADD_SUIVI_STAND_BY";
export const DELETE_SUIVI_STAND_BY = "DELETE_SUIVI_STAND_BY";
export const UPDATE_SUIVI_STAND_BY = "UPDATE_SUIVI_STAND_BY";
export const VALIDATE_SUIVI_STAND_BY = "VALIDATE_SUIVI_STAND_BY";
//confirmationSuiviStandBy
export const GET_CONFIRMATION_SUIVI = "GET_CONFIRMATION_SUIVI";
export const DELETE_CONFIRMATION_SUIVI = "DELETE_CONFIRMATION_SUIVI";
export const UPDATE_CONFIRMATION_SUIVI = "UPDATE_CONFIRMATION_SUIVI";
//enqueteCredit
export const GET_ENQUETE_CREDIT = "GET_ENQUETE_CREDIT";
export const DELETE_ENQUETE_CREDIT = "DELETE_ENQUETE_CREDIT";
export const UPDATE_ENQUETE_CREDIT = "UPDATE_ENQUETE_CREDIT";
//koCredit
export const GET_KO_CREDIT = "GET_KO_CREDIT";
//koInstallation
export const GET_KO_INSTALLATION = "GET_KO_INSTALLATION";
//confirmationRDVInstallation
export const GET_CONFIRMATION_RDV_INSTALLATION =
  "GET_CONFIRMATION_RDV_INSTALLATION";
export const DELETE_CONFIRMATION_RDV_INSTALLATION =
  "DELETE_CONFIRMATION_RDV_INSTALLATION";
export const UPDATE_CONFIRMATION_RDV_INSTALLATION =
  "UPDATE_CONFIRMATION_RDV_INSTALLATION";
export const GET_INSTALLATEURS = "GET_INSTALLATEURS";
//confirmationInstallation
export const GET_VENTE_NIVEAU_INSTALLATEUR = "GET_VENTE_NIVEAU_INSTALLATEUR";
export const DELETE_VENTE_NIVEAU_INSTALLATEUR =
  "DELETE_VENTE_NIVEAU_INSTALLATEUR";
export const UPDATE_VENTE_NIVEAU_INSTALLATEUR =
  "UPDATE_VENTE_NIVEAU_INSTALLATEUR";
//VentesTerminees
export const GET_VENTES_TERMINEES = "GET_VENTES_TERMINEES";
export const DELETE_VENTES_TERMINEES = "DELETE_VENTES_TERMINEES";
export const GET_VENTES_ANNULEES = "GET_VENTES_ANNULEES";
export const GET_VENTES_CHARGE_BACK = "GET_VENTES_CHARGE_BACK";
export const GET_VENTES_CORBEILLE = "GET_VENTES_CORBEILLE";
//privileges
export const UPDATE_PRIVILEGES = "UPDATE_PRIVILEGES";
//commandes
export const GET_COMMANDES = "GET_COMMANDES";
export const ADD_COMMANDE = "ADD_COMMANDE";
export const UPDATE_COMMANDE = "UPDATE_COMMANDE";
export const DELETE_COMMANDE = "DELETE_COMMANDE";
export const UPDATE_COMMANDE_STATUS = "UPDATE_COMMANDE_STATUS";
export const GET_MES_COMMANDES = "GET_MES_COMMANDES";
//etapes
export const GET_ETAPES = "GET_ETAPES";
export const UPDATE_ETAPE = "UPDATE_ETAPE";

// installation
export const GET_INSTALLATIONS = "GET_INSTALLATIONS";
// contrats
export const GET_CONTRATS = "GET_CONTRATS";
// calls
export const GET_CALLS = "GET_CALLS";
export const GET_CALL_ID = "GET_CALL_ID";
export const GET_CALLS_CHIP = "GET_CALLS_CHIP";
export const GET_CALL_ENDED = "GET_CALL_ENDED";
export const GET_CALLS_FOR_DISPLAY = "GET_CALLS_FOR_DISPLAY";     
export const UPDATE_CALL_STATUS = "UPDATE_CALL_STATUS";     
// inventaire installateur
export const GET_INVENTAIRE = "GET_INVENTAIRE"
//notesDynamique
export const GET_NOTES = "GET_NOTES";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const ADD_NOTE = "ADD_NOTE";
//pipeLine
export const GET_PIPELINE_CONTACT = "GET_PIPELINE_CONTACT";
export const GET_PIPELINE_CONTACT_BY_USER = "GET_PIPELINE_CONTACT_BY_USER";
export const GET_PIPELINE_CONTACT_MOBILE = "GET_PIPELINE_CONTACT_MOBILE";
export const GET_PIPELINE_CONTACT_MOBILE_BY_USER = "GET_PIPELINE_CONTACT_MOBILE_BY_USER";
export const MODIFY_STATUT_PIPELINE = "MODIFY_STATUT_PIPELINE";
export const MODIFY_STATUT_PIPELINE_MOBILE = "MODIFY_STATUT_PIPELINE_MOBILE";
export const GET_PIPELINE_CONTACT_AFFECTED = "GET_PIPELINE_CONTACT_AFFECTED";
export const GET_PIPELINE_CONTACT_AFFECTED_BY_USER = "GET_PIPELINE_CONTACT_AFFECTED_BY_USER";
export const GET_PIPELINE_CONTACT_AFFECTED_MOBILE = "GET_PIPELINE_CONTACT_AFFECTED_MOBILE";
export const GET_PIPELINE_CONTACT_AFFECTED_MOBILE_BY_USER = "GET_PIPELINE_CONTACT_AFFECTED_MOBILE_BY_USER";
// RH

export const GET_RH_TIMELINE = "GET_RH_TIMELINE";
// Soumissions
export const GET_SOUMISSIONS = "GET_SOUMISSIONS";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
// RESEARCH_JOURNAL
export const ADD_RESEARCH_JOURNAL = "ADD_RESEARCH_JOURNAL";
export const GET_RESEARCH_JOURNAL = "GET_RESEARCH_JOURNAL";
// DEMANDE AFFECTATION
export const GET_DEMANDE_AFFECTAION = "GET_DEMANDE_AFFECTAION";
export const UPDATE_DEMANDE_AFFECTAION = "UPDATE_DEMANDE_AFFECTAION";

// TYPE SCRIPT APPEL
export const ADD_TYPE_SCRIPT = "ADD_TYPE_SCRIPT";
export const GET_ALL_TYPE_SCRIPT = "GET_ALL_TYPE_SCRIPT";
export const UPDATE_TYPE_SCRIPT_APPEL = "UPDATE_TYPE_SCRIPT_APPEL";

// question script appel
export const ADD_QUESTION_SCRIPT_APPEL = "ADD_QUESTION_SCRIPT_APPEL";
export const UPDATE_QUESTION_SCRIPT_APPEL = "UPDATE_QUESTION_SCRIPT_APPEL";
export const GET_ALL_QUSTIONS_SCRIPT = "GET_ALL_QUSTIONS_SCRIPT";

// script appel
export const ADD_SCRIPT = "ADD_SCRIPT";
export const UPDATE_SCRIPT = "UPDATE_SCRIPT";
export const DELETE_SCRIPT = "DELETE_SCRIPT";
export const GET_ALL_SCRIPTS = "GET_ALL_SCRIPTS";
export const GET_SCRIPT_By_APPROCHE_TYPESCRIPT_SECTTEURDACTIVITE = "GET_SCRIPT_By_APPROCHE_TYPESCRIPT_SECTTEURDACTIVITE";

// Template emails
export const GET_ALL_TEMPLATE_EMAIL = "GET_ALL_TEMPLATE_EMAIL";
export const ADD_TEMPLATE_EMAIL = "ADD_TEMPLATE_EMAIL";
export const UPDATE_TEMPLATE_EMAIL = "UPDATE_TEMPLATE_EMAIL"

// Boite notification
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const GET_NOTIFICATION_BY_USER = "GET_NOTIFICATION_BY_USER";
export const GET_NOTIFICATION_BY_SOURCE = "GET_NOTIFICATION_BY_SOURCE";



/// SALES 
 export const GET_SALES_FOR_DISPLAY = "GET_SALES_FOR_DISPLAY";
 export const GET_AGENDA_INSTALLATION = "GET_AGENDA_INSTALLATION";



/// SECTOR_AVAILABILITY
export const ADD_SECTOR_AVAILABILITY = "ADD_SECTOR_AVAILABILITY";
export const GET_ALL_SECTOR_AVAILABILITY = "GET_ALL__SECTOR_AVAILABILITY";
export const UPDATE_SECTOR_AVAILABILITY = "UPDATE__SECTOR_AVAILABILITY";
export const COPIE_SECTOR_AVAILABILITY = "COPIE_SECTOR_AVAILABILITY";
export const COPIE_SECTOR_AVAILABILITY_BYID = "COPIE_SECTOR_AVAILABILITY_BYID";
export const DELETE_SECTOR_AVAILABILITY_BYID = "DELETE_SECTOR_AVAILABILITY_BYID";

